import { INIT_PAGE_STATE } from "../../constants/constants";

import {
  ADDEDIT_MASTER_CLIENT,
  CLOSE_ADDEDIT_MASTER_CLIENT,
  LOAD_MASTER_CLIENT_LIST,
  LOAD_MASTER_CLIENT_LIST_ERROR,
  LOAD_MASTER_CLIENT_LIST_SUCCESS,
  DELETE_MASTER_CLIENT,
  DELETE_MASTER_CLIENT_ERROR,
  DELETE_MASTER_CLIENT_SUCCESS,
  SAVE_ADDEDIT_MASTER_CLIENT,
  SAVE_ADDEDIT_MASTER_CLIENT_ERROR,
  SAVE_ADDEDIT_MASTER_CLIENT_SUCCESS,
  ADDEDIT_MASTER_CLIENT_SUCCESS,
  ADDEDIT_MASTER_CLIENT_ERROR,
  GET_MASTER_COMPANY_LIST,
  GET_MASTER_COMPANY_LIST_SUCCESS,
  GET_MASTER_COMPANY_LIST_ERROR,
  GET_ALL_DOCUMENT,
  GET_ALL_DOCUMENT_SUCCESS,
  GET_ALL_DOCUMENT_ERROR,
  GET_LIST_FORM_FIELD_CLIENT,
  GET_LIST_FORM_FIELD_CLIENT_SUCCESS,
  GET_LIST_FORM_FIELD_CLIENT_ERROR,
  SAVE_ADDEDIT_FORM_VALUE,
  SAVE_ADDEDIT_FORM_VALUE_SUCCESS,
  SAVE_ADDEDIT_FORM_VALUE_ERROR,
  GET_FORM_VALUE,
  GET_FORM_VALUE_SUCCESS,
  GET_FORM_VALUE_ERROR,
} from "./action";

const INIT_STATE = {
  list: {
    data: null,
    loading: false,
    error: false,
    filter: null,
    orderBy: null,
    sortedBy: "asc",
    filterFields: "name:ilike;mCompany.name:ilike;zipcode:ilike",
    searchJoin: "or",
    page: INIT_PAGE_STATE,
    message: null,
  },
  addEdit: {
    data: null,
    mode: "add",
    loading: false,
    error: false,
    message: null,
  },
  deleting: {
    data: null,
    loading: false,
    error: false,
    message: null,
    confirmed: false,
  },
  listCompany: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  listDocumentType: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  listDocTypeClient: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  addEditFormValue: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  listFormValue: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_MASTER_CLIENT_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          loading: true,
          error: false,
          filter: action.payload.filter,
          orderBy: action.payload.orderBy,
          sortedBy: action.payload.sortedBy,
          page: INIT_PAGE_STATE,
          message: null,
        },
      };

    case LOAD_MASTER_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          page: {
            ...state.list.page,
            currentPage: action.payload.meta.pagination.current_page,
            selectedPageSize: action.payload.meta.pagination.per_page,
            totalItemCount: action.payload.meta.pagination.total,
            totalPage: action.payload.meta.pagination.total_pages,
            itemCount: action.payload.meta.pagination.count,
          },
          loading: false,
          error: false,
          message: null,
        },
      };

    case LOAD_MASTER_CLIENT_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case ADDEDIT_MASTER_CLIENT:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: action.payload.item,
          mode: action.payload.mode,
          loading: false,
          error: false,
          message: null,
          provinces: null,
        },
      };

    case ADDEDIT_MASTER_CLIENT_SUCCESS:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          provinces: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case ADDEDIT_MASTER_CLIENT_ERROR:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          provinces: null,
          loading: false,
          error: true,
          message: action.payload,
        },
      };

    case SAVE_ADDEDIT_MASTER_CLIENT:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: action.payload.item,
          loading: true,
          error: false,
          message: null,
        },
      };

    case SAVE_ADDEDIT_MASTER_CLIENT_SUCCESS:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: null,
          loading: false,
          error: true,
        },
      };

    case SAVE_ADDEDIT_MASTER_CLIENT_ERROR:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          loading: false,
          error: true,
          message: action.payload.toString(),
        },
      };

    case CLOSE_ADDEDIT_MASTER_CLIENT:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: null,
          mode: "add",
          loading: false,
          error: false,
          message: null,
        },
      };

    case DELETE_MASTER_CLIENT:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          data: action.payload.item,
          confirmed: action.payload.confirmed,
          loading: action.payload.confirmed,
          error: false,
          message: null,
        },
      };

    case DELETE_MASTER_CLIENT_SUCCESS:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          data: null,
          loading: false,
          error: false,
          message: null,
          confirmed: false,
        },
      };

    case DELETE_MASTER_CLIENT_ERROR:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          loading: false,
          error: true,
          message: action.payload,
          confirmed: false,
        },
      };

    case GET_MASTER_COMPANY_LIST:
      return {
        ...state,
        listCompany: {
          ...state.listCompany,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_MASTER_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        listCompany: {
          ...state.listCompany,
          data: action.payload.data.map((t) => {
            return {
              ...t,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case GET_MASTER_COMPANY_LIST_ERROR:
      return {
        ...state,
        listCompany: {
          ...state.listCompany,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case GET_ALL_DOCUMENT:
      return {
        ...state,
        listDocumentType: {
          ...state.listDocumentType,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_ALL_DOCUMENT_SUCCESS:
      return {
        ...state,
        listDocumentType: {
          ...state.listDocumentType,
          data: action.payload.data.map((t) => {
            return {
              ...t,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case GET_ALL_DOCUMENT_ERROR:
      return {
        ...state,
        listDocumentType: {
          ...state.listDocumentType,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case GET_LIST_FORM_FIELD_CLIENT:
      return {
        ...state,
        listDocTypeClient: {
          ...state.listDocTypeClient,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_LIST_FORM_FIELD_CLIENT_SUCCESS:
      return {
        ...state,
        listDocTypeClient: {
          ...state.listDocumentlistDocTypeClientType,
          data: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          loading: false,
          error: false,
          message: 'Success',
        },
      };

    case GET_LIST_FORM_FIELD_CLIENT_ERROR:
      return {
        ...state,
        listDocTypeClient: {
          ...state.listDocTypeClient,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case SAVE_ADDEDIT_FORM_VALUE:
      return {
        ...state,
        addEditFormValue: {
          ...state.addEditFormValue,
          data: action.payload.item,
          loading: true,
          error: false,
          message: null,
        },
      };

    case SAVE_ADDEDIT_FORM_VALUE_SUCCESS:
      return {
        ...state,
        addEditFormValue: {
          ...state.addEditFormValue,
          data: null,
          loading: false,
          error: true,
          message: "Success",
        },
      };

    case SAVE_ADDEDIT_FORM_VALUE_ERROR:
      return {
        ...state,
        addEditFormValue: {
          ...state.addEditFormValue,
          loading: false,
          error: true,
          message: action.payload.toString(),
        },
      };

    case GET_FORM_VALUE:
      return {
        ...state,
        listFormValue: {
          ...state.listFormValue,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_FORM_VALUE_SUCCESS:
      return {
        ...state,
        listFormValue: {
          ...state.listFormValue,
          data: action?.payload?.data.map((t) => {
            return {
              ...t,
            };
          }),
          loading: false,
          error: false,
          message: "Success",
        },
      };

    case GET_FORM_VALUE_ERROR:
      return {
        ...state,
        listFormValue: {
          ...state.listFormValue,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };
    default:
      return state;
  }
};
