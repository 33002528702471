import { all } from "redux-saga/effects";
import countrySaga from "./master/master-country/saga";
import taskSaga from "./master/master-task/saga";
import taskDocumentSaga from "./master/master-task-required-document/saga";
import authSagas from "./auth/saga";
import provinceSagas from "./master/master-province/saga";
import citySagas from "./master/master-city/saga";
import companySagas from "./master/master-company/saga";
import jobDescSagas from "./master/master-job-description/saga";
import documentTYpeSagas from "./master/master-document/saga";
import dashboardSagas from "./master/dashboard/saga";
import formFieldSagas from "./master/master-form-field/saga";
import clientSagas from "./client/saga";
import arsipSagas from "./arsip/saga";
import taskListSagas from "./task/list-task/saga";
import taskListColsedSagas from "./task/list-closed/saga";
import taskListRejectSagas from "./task/list-reject/saga";

export default function* rootSaga() {
  yield all([
    countrySaga(),
    taskSaga(),
    authSagas(),
    taskDocumentSaga(),
    provinceSagas(),
    citySagas(),
    companySagas(),
    jobDescSagas(),
    documentTYpeSagas(),
    formFieldSagas(),
    clientSagas(),
    arsipSagas(),
    taskListSagas(),
    taskListColsedSagas(),
    taskListRejectSagas(),
    dashboardSagas(),
  ]);
}
