/* eslint-disable import/prefer-default-export */
export const INIT_PAGE_STATE = {
  currentPage: 1,
  pageSizes: [10, 15, 20],
  selectedPageSize: 10,
  totalItemCount: 0,
  itemCount: 0,
  totalPage: 1,
};

export const INIT_PAGE_LISTCHILD_STATE = {
  currentPageListChild: 1,
  pageListChildSizes: [5, 10],
  selectedListChildPageSize: 5,
  totalItemCountListChild: 0,
  itemCountListChild: 0,
  totalPageListChild: 1,
};

export const Status = {
  Draft: "Draft",
  Onprogress: "Onprogress",
  Done: "Done",
  Cancel: "Batal",
};

export const StatusVisit = {
  Pending: "Pending",
  Onprogress: "Onprogress",
  Done: "Done",
  Cancel: "Batal",
};

export const StatusPlan = {
  Finish: "Finish",
  Progress: "Progress",
};

export const DocType = {
  jpg: "JPG",
  pdf: "PDF",
};

export const initView = {
  id: "id",
  name: "name",
  title: "name",
  relationship: "parent_id",
  children: "children",
};

export const env = {
  REACT_APP: process.env.REACT_APP_BACKEND,
};
