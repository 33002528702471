import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOAD_CURRENT_USER,
  LOAD_CURRENT_USER_SUCCESS,
  LOAD_CURRENT_USER_ERROR,
} from "./actions";
import { getCurrentToken, getCurrentUser } from "../../helpers/Utils";
import { isAuthGuardActive, currentUser } from "../../constants/defaultValues";

const INIT_STATE = {
  currentUser: !isAuthGuardActive ? currentUser : getCurrentUser(),
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  currentToken: getCurrentToken(),
  loading: false,
  error: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentToken: action.payload,
        error: "",
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentToken: null,
        error: action.payload.message,
      };
    case LOAD_CURRENT_USER:
      return { ...state, loading: true, error: "" };
    case LOAD_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: "",
      };
    case LOAD_CURRENT_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    // case FORGOT_PASSWORD:
    //   return { ...state, loading: true, error: "" };
    // case FORGOT_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     forgotUserMail: action.payload,
    //     error: "",
    //   };
    // case FORGOT_PASSWORD_ERROR:
    //   return {
    //     ...state,
    //     loading: false,
    //     forgotUserMail: "",
    //     error: action.payload.message,
    //   };
    // case RESET_PASSWORD:
    //   return { ...state, loading: true, error: "" };
    // case RESET_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     newPassword: action.payload,
    //     resetPasswordCode: "",
    //     error: "",
    //   };
    // case RESET_PASSWORD_ERROR:
    //   return {
    //     ...state,
    //     loading: false,
    //     newPassword: "",
    //     resetPasswordCode: "",
    //     error: action.payload.message,
    //   };
    // case REGISTER_USER:
    //   return { ...state, loading: true, error: "" };
    // case REGISTER_USER_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     currentUser: action.payload,
    //     error: "",
    //   };
    // case REGISTER_USER_ERROR:
    //   return {
    //     ...state,
    //     loading: false,
    //     currentUser: null,
    //     error: action.payload.message,
    //   };
    // case LOGOUT_USER:
    //   return { ...state, currentUser: null, error: "" };
    // case SET_GLOBAL_NOTIFICATION_CHANNEL:
    //   return {
    //     ...state,
    //     channel: {
    //       ...state.channel,
    //       globalNotification: action.payload.channel,
    //     },
    //   };
    default:
      return { ...state };
  }
};
