import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_MASTER_TASK_LIST = "LOAD_MASTER_TASK_LIST";
export const LOAD_MASTER_TASK_LIST_SUCCESS =
  "LOAD_MASTER_TASK_LIST_SUCCESS";
export const LOAD_MASTER_TASK_LIST_ERROR = "LOAD_MASTER_TASK_LIST_ERROR";

export const ADDEDIT_MASTER_TASK = "ADDEDIT_MASTER_TASK";
export const ADDEDIT_MASTER_TASK_REQUIRED_DOCUMENT = "ADDEDIT_MASTER_TASK_REQUIRED_DOCUMENT";
export const SAVE_ADDEDIT_MASTER_TASK = "SAVE_ADDEDIT_MASTER_TASK";
export const SAVE_ADDEDIT_MASTER_TASK_SUCCESS =
  "SAVE_ADDEDIT_MASTER_TASK_SUCCESS";
export const SAVE_ADDEDIT_MASTER_TASK_ERROR =
  "SAVE_ADDEDIT_MASTER_TASK_ERROR";
export const CLOSE_ADDEDIT_MASTER_TASK = "CLOSE_ADDEDIT_MASTER_TASK";
export const CLOSE_ADDEDIT_MASTER_TASK_REQUIRED_DOCUMENT = "CLOSE_ADDEDIT_MASTER_TASK_REQUIRED_DOCUMENT";

export const DELETE_MASTER_TASK = "DELETE_MASTER_TASK";
export const DELETE_MASTER_TASK_SUCCESS = "DELETE_MASTER_TASK_SUCCESS";
export const DELETE_MASTER_TASK_ERROR = "DELETE_MASTER_TASK_ERROR";

export const loadMasterTaskList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_TASK_LIST,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterTaskListSuccess = (data) => ({
  type: LOAD_MASTER_TASK_LIST_SUCCESS,
  payload: data,
});

export const loadMasterTaskListError = (error) => ({
  type: LOAD_MASTER_TASK_LIST_ERROR,
  payload: error,
});

export const addeditMasterTask = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_TASK,
  payload: {
    item,
    mode,
  },
});

export const addeditMasterTaskRequiredDocument = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_TASK_REQUIRED_DOCUMENT,
  payload: {
    item,
    mode,
  },
});

export const closeAddeditMasterTask = () => ({
  type: CLOSE_ADDEDIT_MASTER_TASK,
});

export const closeAddeditMasterTaskRequiredDocument = () => ({
  type: CLOSE_ADDEDIT_MASTER_TASK_REQUIRED_DOCUMENT,
});

export const saveAddeditMasterTask = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_MASTER_TASK,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditMasterTaskSuccess = (data) => ({
  type: SAVE_ADDEDIT_MASTER_TASK_SUCCESS,
  payload: data,
});

export const saveAddeditMasterTaskError = (error) => ({
  type: SAVE_ADDEDIT_MASTER_TASK_ERROR,
  payload: error,
});

export const deleteMasterTask = (data, confirmed = false) => ({
  type: DELETE_MASTER_TASK,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteMasterTaskSuccess = () => ({
  type: DELETE_MASTER_TASK_SUCCESS,
});

export const deleteMasterTaskError = (error) => ({
  type: DELETE_MASTER_TASK_ERROR,
  payload: error,
});
