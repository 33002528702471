/* eslint-disable import/no-cycle */
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import * as action from "./action";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";

export const getListState = (state) => state.provinceApp.list;

const loadMasterProvinceListAsync = async (query) => GET(`/province?${query}`);

const addMasterProvinceAsync = async (item) => POST(`/province`, item);
const editMasterProvinceAsync = async (id, item) =>
  PUT(`/province/${id}`, item);
const deleteMasterProvinceAsync = async (id) => DELETE(`/province/${id}`);

// BIND TO ALL COUNTRY
const getMasterCountryListAsync = async () => GET(`/country/all`);

function* loadMasterProvinceList({ payload }) {
  try {
    const list = yield select(getListState);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadMasterProvinceListAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterProvinceListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterProvinceListError(error));
  }
}

function* saveAddeditMasterProvince({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editMasterProvinceAsync,
          payload.item.id,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addMasterProvinceAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditMasterProvinceSuccess(response));
      yield put(action.loadMasterProvinceList());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditMasterProvinceError(error));
  }
}

function* deleteMasterProvince({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteMasterProvinceAsync, payload.item.id);
        yield put(action.deleteMasterProvinceSuccess());
        yield put(action.loadMasterProvinceList());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteMasterProvinceError(error));
  }
}

function* getMasterCountryList() {
  try {
    let response = null;
    response = yield call(getMasterCountryListAsync);
    yield put(action.getCountryListSuccess(response));
  } catch (error) {
    yield put(action.getCountryListError(error));
  }
}

export function* watchLoadMasterProvinceList() {
  yield takeEvery(action.LOAD_MASTER_PROVINCE_LIST, loadMasterProvinceList);
}

export function* watchSaveAddeditMasterProvince() {
  yield takeEvery(
    action.SAVE_ADDEDIT_MASTER_PROVINCE,
    saveAddeditMasterProvince
  );
}

export function* watchDeleteMasterProvince() {
  yield takeEvery(action.DELETE_MASTER_PROVINCE, deleteMasterProvince);
}

export function* watchGetCountryList() {
  yield takeEvery(action.GET_MASTER_COUNTRY_LIST, getMasterCountryList);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterProvinceList),
    fork(watchSaveAddeditMasterProvince),
    fork(watchDeleteMasterProvince),
    fork(watchGetCountryList),
  ]);
}
