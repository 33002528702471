import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "./app/redux/store";
import "./i18n";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./app/App"));
// eslint-disable-next-line import/prefer-default-export
export const store = configureStore();

const Main = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div className="loading" />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));
