import { INIT_PAGE_STATE } from "../../../constants/constants";

import {
  LOAD_LIST_TASK_CLOSED,
  LOAD_LIST_TASK_CLOSED_ERROR,
  LOAD_LIST_TASK_CLOSED_SUCCESS,
  LOAD_LIST_TASK_DETAIL,
  LOAD_LIST_TASK_DETAIL_ERROR,
  LOAD_LIST_TASK_DETAIL_SUCCESS,
  ADDEDIT_LIST_TASK,
  ADDEDIT_LIST_TASK_ERROR,
  ADDEDIT_LIST_TASK_SUCCESS,
  ADDEDIT_LIST_TASK_DETAIL,
  ADDEDIT_LIST_TASK_DETAIL_ERROR,
  ADDEDIT_LIST_TASK_DETAIL_SUCCESS,
  CLOSE_ADDEDIT_LIST_TASK_DETAIL,
  SAVE_ADDEDIT_LIST_TASK,
  SAVE_ADDEDIT_LIST_TASK_ERROR,
  SAVE_ADDEDIT_LIST_TASK_SUCCESS,
  SAVE_ADDEDIT_LIST_TASK_DETAIL,
  SAVE_ADDEDIT_LIST_TASK_DETAIL_ERROR,
  SAVE_ADDEDIT_LIST_TASK_DETAIL_SUCCESS,
  CLOSE_ADDEDIT_LIST_TASK,
  DELETE_LIST_TASK,
  DELETE_LIST_TASK_ERROR,
  DELETE_LIST_TASK_SUCCESS,

  //TODO BENERIN JANGAN BERULANG KAYA GINI LAGI
  LOAD_MASTER_CLIENT_LIST_TASK_CLOSED,
  LOAD_MASTER_CLIENT_LIST_TASK_CLOSED_ERROR,
  LOAD_MASTER_CLIENT_LIST_TASK_CLOSED_SUCCESS,
  LOAD_MASTER_JOB_DESCRIPTION_LIST,
  LOAD_MASTER_JOB_DESCRIPTION_LIST_ERROR,
  LOAD_MASTER_JOB_DESCRIPTION_LIST_SUCCESS,
  LOAD_MASTER_TASK_LIST,
  LOAD_MASTER_TASK_LIST_ERROR,
  LOAD_MASTER_TASK_LIST_SUCCESS,
} from "./action";

const INIT_STATE = {
  list: {
    data: null,
    loading: false,
    error: false,
    filter: null,
    orderBy: null,
    sortedBy: "asc",
    filterFields: "name:ilike;mProvince.name:ilike;zipcode:ilike",
    searchJoin: "or",
    page: INIT_PAGE_STATE,
    message: null,
  },
  addEdit: {
    data: null,
    mode: "add",
    loading: false,
    error: false,
    message: null,
  },
  addEditDetail: {
    data: null,
    mode: "add",
    loading: false,
    error: false,
    message: null,
  },

  addEditDetailUplodad: {
    data: null,
    mode: "add",
    loading: false,
    error: false,
    message: null,
  },

  deleting: {
    data: null,
    loading: false,
    error: false,
    message: null,
    confirmed: false,
  },
  detail: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },

  listClient: {
    data: null,
    loading: false,
    error: false,
    filter: null,
    orderBy: null,
    sortedBy: "asc",
    filterFields: "name:ilike;mCompany.name:ilike;zipcode:ilike",
    searchJoin: "or",
    page: INIT_PAGE_STATE,
    message: null,
  },

  listJob: {
    data: null,
    loading: false,
    error: false,
    filter: null,
    orderBy: null,
    sortedBy: "asc",
    filterFields: "id:ilike;name:ilike",
    searchJoin: "or",
    page: INIT_PAGE_STATE,
    message: null,
  },

  listTask: {
    data: null,
    loading: false,
    error: false,
    filter: null,
    orderBy: null,
    sortedBy: "asc",
    filterFields: "id:ilike;name:ilike",
    searchJoin: "or",
    page: INIT_PAGE_STATE,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_LIST_TASK_CLOSED:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          loading: true,
          error: false,
          filter: action.payload.filter,
          orderBy: action.payload.orderBy,
          sortedBy: action.payload.sortedBy,
          page: INIT_PAGE_STATE,
          message: null,
        },
      };

    case LOAD_LIST_TASK_CLOSED_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          page: {
            ...state.list.page,
            currentPage: action.payload.meta.pagination.current_page,
            selectedPageSize: action.payload.meta.pagination.per_page,
            totalItemCount: action.payload.meta.pagination.total,
            totalPage: action.payload.meta.pagination.total_pages,
            itemCount: action.payload.meta.pagination.count,
          },
          loading: false,
          error: false,
          message: null,
        },
      };

    case LOAD_LIST_TASK_CLOSED_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case LOAD_LIST_TASK_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case LOAD_LIST_TASK_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: action.payload.data.map((t) => {
            return {
              ...t,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case LOAD_LIST_TASK_DETAIL_ERROR:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case ADDEDIT_LIST_TASK:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: action.payload.item,
          mode: action.payload.mode,
          loading: false,
          error: false,
          message: null,
        },
      };

    case ADDEDIT_LIST_TASK_SUCCESS:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case ADDEDIT_LIST_TASK_ERROR:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: null,
          loading: false,
          error: true,
          message: action.payload,
        },
      };

    case ADDEDIT_LIST_TASK_DETAIL:
      return {
        ...state,
        addEditDetail: {
          ...state.addEditDetail,
          data: action.payload.item,
          mode: action.payload.mode,
          loading: false,
          error: false,
          message: null,
        },
      };

    case ADDEDIT_LIST_TASK_DETAIL_SUCCESS:
      return {
        ...state,
        addEditDetail: {
          ...state.addEditDetail,
          data: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          loading: false,
          error: false,
          message: null,
        },
      };

    case ADDEDIT_LIST_TASK_DETAIL_ERROR:
      return {
        ...state,
        addEditDetail: {
          ...state.addEditDetail,
          data: null,
          loading: false,
          error: true,
          message: action.payload,
        },
      };

    case SAVE_ADDEDIT_LIST_TASK:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: action.payload.item,
          loading: true,
          error: false,
          message: null,
        },
      };

    case SAVE_ADDEDIT_LIST_TASK_SUCCESS:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: null,
          loading: false,
          error: true,
        },
      };

    case SAVE_ADDEDIT_LIST_TASK_ERROR:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          loading: false,
          error: true,
          message: action.payload.toString(),
        },
      };

    case SAVE_ADDEDIT_LIST_TASK_DETAIL:
      return {
        ...state,
        addEditDetailUplodad: {
          ...state.addEditDetailUplodad,
          data: action.payload.item,
          loading: true,
          error: false,
          message: null,
        },
      };

    case SAVE_ADDEDIT_LIST_TASK_DETAIL_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        addEditDetailUplodad: {
          ...state.addEditDetailUplodad,
          data: null,
          loading: false,
          error: true,
        },
      };

    case SAVE_ADDEDIT_LIST_TASK_DETAIL_ERROR:
      return {
        ...state,
        addEditDetailUplodad: {
          ...state.addEditDetailUplodad,
          loading: false,
          error: true,
          message: action.payload.toString(),
        },
      };

    case CLOSE_ADDEDIT_LIST_TASK:
      return {
        ...state,
        addEdit: {
          ...state.addEdit,
          data: null,
          mode: "add",
          loading: false,
          error: false,
          message: null,
        },
      };
    case CLOSE_ADDEDIT_LIST_TASK_DETAIL:
      return {
        ...state,
        addEditDetail: {
          ...state.addEditDetail,
          data: null,
          mode: "add",
          loading: false,
          error: false,
          message: null,
        },
      };

    case DELETE_LIST_TASK:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          data: action.payload.item,
          confirmed: action.payload.confirmed,
          loading: action.payload.confirmed,
          error: false,
          message: null,
        },
      };

    case DELETE_LIST_TASK_SUCCESS:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          data: null,
          loading: false,
          error: false,
          message: null,
          confirmed: false,
        },
      };

    case DELETE_LIST_TASK_ERROR:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          loading: false,
          error: true,
          message: action.payload,
          confirmed: false,
        },
      };

    //TODO BENERIN JANGAN BERULANG KAYA GINI LAGI
    case LOAD_MASTER_CLIENT_LIST_TASK_CLOSED:
      return {
        ...state,
        listClient: {
          ...state.listClient,
          data: null,
          loading: true,
          error: false,
          filter: action.payload.filter,
          orderBy: action.payload.orderBy,
          sortedBy: action.payload.sortedBy,
          page: INIT_PAGE_STATE,
          message: null,
        },
      };

    case LOAD_MASTER_CLIENT_LIST_TASK_CLOSED_SUCCESS:
      return {
        ...state,
        listClient: {
          ...state.listClient,
          data: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          page: {
            ...state.listClient.page,
            currentPage: action.payload.meta.pagination.current_page,
            selectedPageSize: action.payload.meta.pagination.per_page,
            totalItemCount: action.payload.meta.pagination.total,
            totalPage: action.payload.meta.pagination.total_pages,
            itemCount: action.payload.meta.pagination.count,
          },
          loading: false,
          error: false,
          message: null,
        },
      };

    case LOAD_MASTER_CLIENT_LIST_TASK_CLOSED_ERROR:
      return {
        ...state,
        listClient: {
          ...state.listClient,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case LOAD_MASTER_JOB_DESCRIPTION_LIST:
      return {
        ...state,
        listJob: {
          ...state.listJob,
          data: null,
          loading: true,
          error: false,
          filter: action.payload.filter,
          orderBy: action.payload.orderBy,
          sortedBy: action.payload.sortedBy,
          page: INIT_PAGE_STATE,
          message: null,
        },
      };

    case LOAD_MASTER_JOB_DESCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        listJob: {
          ...state.listJob,
          data: action.payload.data.map((t) => {
            return {
              ...t,
              ...t.attributes,
            };
          }),
          page: {
            ...state.listJob.page,
            currentPage: action.payload.meta.pagination.current_page,
            selectedPageSize: action.payload.meta.pagination.per_page,
            totalItemCount: action.payload.meta.pagination.total,
            totalPage: action.payload.meta.pagination.total_pages,
            itemCount: action.payload.meta.pagination.count,
          },
          loading: false,
          error: false,
          message: null,
        },
      };

    case LOAD_MASTER_JOB_DESCRIPTION_LIST_ERROR:
      return {
        ...state,
        listJob: {
          ...state.listJob,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case LOAD_MASTER_TASK_LIST:
      return {
        ...state,
        listTask: {
          ...state.listTask,
          data: null,
          loading: true,
          error: false,
          filter: action.payload.filter,
          orderBy: action.payload.orderBy,
          sortedBy: action.payload.sortedBy,
          page: INIT_PAGE_STATE,
          message: null,
        },
      };

    case LOAD_MASTER_TASK_LIST_SUCCESS:
      return {
        ...state,
        listTask: {
          ...state.listTask,
          data: action.payload.data,
          page: {
            ...state.listTask.page,
            currentPage: action.payload.current_page,
            selectedPageSize: action.payload.per_page,
            totalItemCount: action.payload.total,
            totalPage: action.payload.last_page,
            itemCount: action.payload.total,
          },
          loading: false,
          error: false,
          message: null,
        },
      };

    case LOAD_MASTER_TASK_LIST_ERROR:
      return {
        ...state,
        listTask: {
          ...state.listTask,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    default:
      return state;
  }
};
