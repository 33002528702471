import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_LIST_TASK = "LOAD_LIST_TASK";
export const LOAD_LIST_TASK_SUCCESS = "LOAD_LIST_TASK_SUCCESS";
export const LOAD_LIST_TASK_ERROR = "LOAD_LIST_TASK_LIST_ERROR";

export const LOAD_LIST_TASK_DETAIL = "LOAD_LIST_TASK_DETAIL";
export const LOAD_LIST_TASK_DETAIL_SUCCESS = "LOAD_LIST_TASK_DETAIL_SUCCESS";
export const LOAD_LIST_TASK_DETAIL_ERROR = "LOAD_LIST_TASK_DETAIL_LIST_ERROR";

export const ADDEDIT_LIST_TASK = "ADDEDIT_LIST_TASK";
export const ADDEDIT_LIST_TASK_SUCCESS = "ADDEDIT_LIST_TASK_SUCCESS";
export const ADDEDIT_LIST_TASK_ERROR = "ADDEDIT_LIST_TASK_ERROR";

export const ADDEDIT_LIST_TASK_DETAIL = "ADDEDIT_LIST_TASK_DETAIL";
export const ADDEDIT_LIST_TASK_DETAIL_SUCCESS =
  "ADDEDIT_LIST_TASK_DETAIL_SUCCESS";
export const ADDEDIT_LIST_TASK_DETAIL_ERROR = "ADDEDIT_LIST_TASK_DETAIL_ERROR";

export const SAVE_ADDEDIT_LIST_TASK = "SAVE_ADDEDIT_LIST_TASK";
export const SAVE_ADDEDIT_LIST_TASK_SUCCESS = "SAVE_ADDEDIT_LIST_TASK_SUCCESS";
export const SAVE_ADDEDIT_LIST_TASK_ERROR = "SAVE_ADDEDIT_LIST_TASK_ERROR";

export const SAVE_ADDEDIT_LIST_TASK_DETAIL = "SAVE_ADDEDIT_LIST_TASK_DETAIL";
export const SAVE_ADDEDIT_LIST_TASK_DETAIL_SUCCESS =
  "SAVE_ADDEDIT_LIST_TASK_DETAIL_SUCCESS";
export const SAVE_ADDEDIT_LIST_TASK_DETAIL_ERROR =
  "SAVE_ADDEDIT_LIST_TASK_DETAIL_ERROR";

export const CLOSE_ADDEDIT_LIST_TASK = "CLOSE_ADDEDIT_LIST_TASK";
export const CLOSE_ADDEDIT_LIST_TASK_DETAIL = "CLOSE_ADDEDIT_LIST_TASK_DETAIL";

export const DELETE_LIST_TASK = "DELETE_LIST_TASK";
export const DELETE_LIST_TASK_SUCCESS = "DELETE_LIST_TASK_SUCCESS";
export const DELETE_LIST_TASK_ERROR = "DELETE_LIST_TASK_ERROR";

//TODO BENERIN JANGAN BERULANG KAYA GINI LAGI
export const LOAD_MASTER_CLIENT_LIST_TASK = "LOAD_MASTER_CLIENT_LIST_TASK";
export const LOAD_MASTER_CLIENT_LIST_TASK_SUCCESS =
  "LOAD_MASTER_CLIENT_LIST_TASK_SUCCESS";
export const LOAD_MASTER_CLIENT_LIST_TASK_ERROR =
  "LOAD_MASTER_CLIENT_LIST_TASK_ERROR";

export const LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK =
  "LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK";
export const LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK_SUCCESS =
  "LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK_SUCCESS";
export const LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK_ERROR =
  "LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK_ERROR";

export const LOAD_MASTER_TASK = "LOAD_MASTER_TASK";
export const LOAD_MASTER_TASK_SUCCESS = "LOAD_MASTER_TASK_SUCCESS";
export const LOAD_MASTER_TASK_ERROR = "LOAD_MASTER_TASK_ERROR";

export const REJECT_TASK = "REJECT_TASK";

// APPROVE TASK

export const ADD_APPROVE_TASK = "ADD_APPROVE_TASK";
export const ADD_APPROVE_TASK_SUCCESS = "ADD_APPROVE_TASK_SUCCESS";
export const ADD_APPROVE_TASK_ERROR = "ADD_APPROVE_TASK_ERROR";

export const loadListTask = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_LIST_TASK,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadListTaskSuccess = (data) => ({
  type: LOAD_LIST_TASK_SUCCESS,
  payload: data,
});

export const loadListTaskError = (error) => ({
  type: LOAD_LIST_TASK_ERROR,
  payload: error,
});

export const loadListTaskDetail = (p) => {
  const param = {
    ...{
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
    },
    ...p,
  };
  return {
    type: LOAD_LIST_TASK_DETAIL,
    payload: {
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
    },
  };
};

export const loadListTaskDetailSuccess = (data) => ({
  type: LOAD_LIST_TASK_DETAIL_SUCCESS,
  payload: data,
});

export const loadListTaskDetailError = (error) => ({
  type: LOAD_LIST_TASK_DETAIL_ERROR,
  payload: error,
});

export const addeditListTask = (item, mode = "add") => ({
  type: ADDEDIT_LIST_TASK,
  payload: {
    item,
    mode,
  },
});

export const addeditListTaskSuccess = (data) => ({
  type: ADDEDIT_LIST_TASK_SUCCESS,
  payload: data,
});

export const addeditListTaskError = (error) => ({
  type: ADDEDIT_LIST_TASK_ERROR,
  payload: error,
});

export const addeditListTaskDetail = (item, mode = "add") => ({
  type: ADDEDIT_LIST_TASK_DETAIL,
  payload: {
    item,
    mode,
  },
});

export const addeditListTaskDetailSuccess = (data) => ({
  type: ADDEDIT_LIST_TASK_DETAIL_SUCCESS,
  payload: data,
});

export const addeditListTaskDetailError = (error) => ({
  type: ADDEDIT_LIST_TASK_DETAIL_ERROR,
  payload: error,
});

export const closeAddeditListTask = () => ({
  type: CLOSE_ADDEDIT_LIST_TASK,
});

export const closeAddeditListTaskDetail = () => ({
  type: CLOSE_ADDEDIT_LIST_TASK_DETAIL,
});

export const saveAddeditListTask = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_LIST_TASK,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditListTaskSuccess = (data) => ({
  type: SAVE_ADDEDIT_LIST_TASK_SUCCESS,
  payload: data,
});

export const saveAddeditListTaskError = (error) => ({
  type: SAVE_ADDEDIT_LIST_TASK_ERROR,
  payload: error,
});

export const saveAddeditListTaskDetail = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_LIST_TASK_DETAIL,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditListTaskDetailSuccess = (data) => ({
  type: SAVE_ADDEDIT_LIST_TASK_DETAIL_SUCCESS,
  payload: data,
});

export const saveAddeditListTaskDetailError = (error) => ({
  type: SAVE_ADDEDIT_LIST_TASK_DETAIL_ERROR,
  payload: error,
});

export const rejectTask = (idTask) => ({
  type: REJECT_TASK,
  payload: {
    id: idTask,
  },
});

export const deleteListTask = (data, confirmed = false) => ({
  type: DELETE_LIST_TASK,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteListTaskSuccess = () => ({
  type: DELETE_LIST_TASK_SUCCESS,
});

export const deleteListTaskError = (error) => ({
  type: DELETE_LIST_TASK_ERROR,
  payload: error,
});

//TODO BENERIN JANGAN BERULANG KAYA GINI LAGI
export const loadMasterClientList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_CLIENT_LIST_TASK,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterClientListSuccess = (data) => ({
  type: LOAD_MASTER_CLIENT_LIST_TASK_SUCCESS,
  payload: data,
});

export const loadMasterClientListError = (error) => ({
  type: LOAD_MASTER_CLIENT_LIST_TASK_ERROR,
  payload: error,
});

export const loadMasterTaskList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_TASK,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterTaskListSuccess = (data) => ({
  type: LOAD_MASTER_TASK_SUCCESS,
  payload: data,
});

export const loadMasterTaskListError = (error) => ({
  type: LOAD_MASTER_TASK_ERROR,
  payload: error,
});

export const loadMasterJobDescriptionList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterJobDescriptionListSuccess = (data) => ({
  type: LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK_SUCCESS,
  payload: data,
});

export const loadMasterJobDescriptionListError = (error) => ({
  type: LOAD_MASTER_JOB_DESCRIPTION_LIST_TASK_ERROR,
  payload: error,
});

// APPROVE TASK
export const addApproveTask = (data) => ({
  type: ADD_APPROVE_TASK,
  payload: data,
});

export const addApproveTaskSuccess = (data) => ({
  type: ADD_APPROVE_TASK_SUCCESS,
  payload: data,
});

export const addApproveTaskError = (error) => ({
  type: ADD_APPROVE_TASK_ERROR,
  payload: error,
});
