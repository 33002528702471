import React from "react";
import cogoToast from "cogo-toast";

export const ErrorLoginUsername = (e) => {
  cogoToast.error(e, { position: "top-right" }).then(function () {
    window.location.reload();
  });
};

export const ErrorLoginPassword = (e) => {
  cogoToast.error(e, { position: "top-right" }).then(function () {
    window.location.reload();
  });
};

export const SuccessCreate = () => {
  cogoToast.success("Data has been saved successfully", {
    position: "top-right",
  });
};

export const SuccessDelete = () => {
  cogoToast.success("Data has been deleted successfully", {
    position: "top-right",
  });
};

export const ErrorCreate = () => {
  cogoToast.error("Error! data not saved", { position: "top-right" });
};

export const SuccessUpdate = () => {
  cogoToast.success("Data has been updated successfully", {
    position: "top-right",
  });
};

export const handlingError = (e) => {
  cogoToast.error(e, { position: "top-right" }).then(function () {
    window.location.reload();
  });
};
