/* eslint-disable import/no-cycle */
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";

import * as action from "./action";

export const getListState = (state) => state.formFieldApp.list;
export const getListStateDocument = (state) =>
  state.documentTypeApp.addEditFormField;

const loadMasterFormFieldListAsync = async (query) =>
  GET(`/form-field?${query}`);

const addMasterFormFieldAsync = async (item) => POST(`/form-field`, item);
const editMasterFormFieldAsync = async (id, item) =>
  PUT(`/form-field/${id}`, item);
const deleteMasterFormFieldAsync = async (id) => DELETE(`/form-field/${id}`);

function* loadMasterFormFieldList({ payload }) {
  try {
    const list = yield select(getListState);
    const listDocument = yield select(getListStateDocument);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
      {
        name: "idDocumentType",
        value: listDocument.data.id,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadMasterFormFieldListAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterFormFieldListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterFormFieldListError(error));
  }
}

function* saveAddeditMasterFormField({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editMasterFormFieldAsync,
          payload.item.id,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addMasterFormFieldAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditMasterFormFieldSuccess(response));
      yield put(action.loadMasterFormFieldList());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditMasterFormFieldError(error));
  }
}

function* deleteMasterFormField({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteMasterFormFieldAsync, payload.item.id);
        yield put(action.deleteMasterFormFieldSuccess());
        yield put(action.loadMasterFormFieldList());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteMasterFormFieldError(error));
  }
}

export function* watchLoadMasterFormFieldList() {
  yield takeEvery(action.LOAD_MASTER_FORM_FIELD_LIST, loadMasterFormFieldList);
}

export function* watchSaveAddeditMasterFormField() {
  yield takeEvery(
    action.SAVE_ADDEDIT_MASTER_FORM_FIELD,
    saveAddeditMasterFormField
  );
}

export function* watchDeleteMasterFormField() {
  yield takeEvery(action.DELETE_MASTER_FORM_FIELD, deleteMasterFormField);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterFormFieldList),
    fork(watchSaveAddeditMasterFormField),
    fork(watchDeleteMasterFormField),
  ]);
}
