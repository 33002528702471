/* eslint-disable import/no-cycle */
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";

import * as action from "./action";

export const getListState = (state) => state.taskApp.list;

const loadMasterTaskAsync = async (query) => GET(`/task?${query}`);

const addMasterTaskAsync = async (item) => POST(`/task`, item);
const editMasterTaskAsync = async (id, item) => PUT(`/task/${id}`, item);
const deleteMasterTaskAsync = async (id) => DELETE(`/task/${id}`);

function* loadMasterTaskList({ payload }) {
  try {
    const list = yield select(getListState);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadMasterTaskAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterTaskListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterTaskListError(error));
  }
}

function* saveAddeditMasterTask({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editMasterTaskAsync,
          payload.item.id,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addMasterTaskAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditMasterTaskSuccess(response));
      yield put(action.loadMasterTaskList());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditMasterTaskError(error));
  }
}

function* deleteMasterTask({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteMasterTaskAsync, payload.item.id);
        yield put(action.deleteMasterTaskSuccess());
        yield put(action.loadMasterTaskList());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteMasterTaskError(error));
  }
}

export function* watchLoadMasterTaskList() {
  yield takeEvery(action.LOAD_MASTER_TASK_LIST, loadMasterTaskList);
}

export function* watchSaveAddeditMasterTask() {
  yield takeEvery(action.SAVE_ADDEDIT_MASTER_TASK, saveAddeditMasterTask);
}

export function* watchDeleteMasterTask() {
  yield takeEvery(action.DELETE_MASTER_TASK, deleteMasterTask);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterTaskList),
    fork(watchSaveAddeditMasterTask),
    fork(watchDeleteMasterTask),
  ]);
}
