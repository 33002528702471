import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_STAT_TASK_LIST = "LOAD_STAT_TASK_LIST";
export const LOAD_STAT_TASK_LIST_SUCCESS =
  "LOAD_STAT_TASK_LIST_SUCCESS";
export const LOAD_STAT_TASK_LIST_ERROR = "LOAD_STAT_TASK_LIST_ERROR";

export const LOAD_CLIENT_NEED_ATT = "LOAD_CLIENT_NEED_ATT";
export const LOAD_CLIENT_NEED_ATT_SUCCESS =
  "LOAD_CLIENT_NEED_ATT_SUCCESS";
export const LOAD_CLIENT_NEED_ATT_ERROR = "LOAD_CLIENT_NEED_ATT_ERROR";

export const LOAD_STAT_NEED_ATT = "LOAD_STAT_NEED_ATT";
export const LOAD_STAT_NEED_ATT_SUCCESS =
  "LOAD_STAT_NEED_ATT_SUCCESS";
export const LOAD_STAT_NEED_ATT_ERROR = "LOAD_STAT_NEED_ATT_ERROR";

export const loadStatTaskList = () => {
  return {
    type: LOAD_STAT_TASK_LIST
  };
};

export const loadStatTaskListSuccess = (data) => ({
  type: LOAD_STAT_TASK_LIST_SUCCESS,
  payload: data,
});

export const loadStatTaskListError = (error) => ({
  type: LOAD_STAT_TASK_LIST_ERROR,
  payload: error,
});

export const loadStatNeedAtt = () => {
  return {
    type: LOAD_STAT_NEED_ATT
  };
};

export const loadStatNeedAttSuccess = (data) => ({
  type: LOAD_STAT_NEED_ATT_SUCCESS,
  payload: data,
});

export const loadStatNeedAttError = (error) => ({
  type: LOAD_STAT_NEED_ATT_ERROR,
  payload: error,
});

export const loadClientNeedAtt = () => {
  return {
    type: LOAD_CLIENT_NEED_ATT
  };
};

export const loadClientNeedAttSuccess = (data) => ({
  type: LOAD_CLIENT_NEED_ATT_SUCCESS,
  payload: data,
});

export const loadClientNeedAttError = (error) => ({
  type: LOAD_CLIENT_NEED_ATT_ERROR,
  payload: error,
});
