import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { simplePOST, GET } from "../../helpers/apiHelper";
// import { getFormData } from '../../helpers/Utils';
import * as action from "./actions";
import { adminRoot, UserRole } from "../../constants/defaultValues";
import { setCurrentToken, setCurrentUser } from "../../helpers/Utils";

const authLoginAsync = async (email, password) =>
  simplePOST(
    `/login`,
    JSON.stringify({
      username: email,
      password,
    })
  );

function* loginWithEmailPassword({ payload }) {
  console.log("payload", payload);
  const { email, password } = payload.user;
  const { history } = payload;
  console.log(history);
  try {
    const token = yield call(authLoginAsync, email, password);
    console.log("token", token);
    if (!token.message) {
      setCurrentToken(token);
      yield all([
        put(action.loginUserSuccess(token)),
        put(action.loadCurrentUser(history)),
      ]);
    } else if (token.message) {
      yield put(action.loginUserError(token.message));
    } else {
      yield put(action.loginUserError("unknown error"));
    }
  } catch (error) {
    yield put(action.loginUserError(`${error}`));
  }
}

const loadUserAsync = async () => GET("/profile");

function* loadUser({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(loadUserAsync);
    console.log("response", response);
    if (!response.message && response.data) {
      const item = {
        id: response.data.id,
        role: UserRole.Admin,
        ...response.data.attributes,
      };
      setCurrentUser(item);
      yield put(action.loadCurrentUserSuccess(item));
      history.push(adminRoot);
    } else if (response.message) {
      yield put(action.loadCurrentUserError(response.message));
    } else {
      yield put(action.loadCurrentUserError("unknown error"));
    }
  } catch (error) {
    yield put(action.loadCurrentUserError(`${error}`));
  }
}

export function* watchLoadUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(action.LOAD_CURRENT_USER, loadUser);
}

export function* watchLoginEmailPassword() {
  yield takeEvery(action.LOGIN_USER, loginWithEmailPassword);
}

export default function* rootSaga() {
  yield all([fork(watchLoadUser, watchLoginEmailPassword)]);
}
