import { INIT_PAGE_STATE } from "../../constants/constants";

import {
  GET_MASTER_COMPANY_LIST,
  GET_MASTER_COMPANY_LIST_SUCCESS,
  GET_MASTER_COMPANY_LIST_ERROR,
  GET_CLIENT_LIST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_ERROR,
  CHECK_BERKAS,
  CHECK_BERKAS_SUCCESS,
  CHECK_BERKAS_ERROR,
  GET_BERKAS_CLIENT_LIST,
  GET_BERKAS_CLIENT_LIST_SUCCESS,
  GET_BERKAS_CLIENT_LIST_ERROR,
  GET_DOCUMENTS_BERKAS_CLIENT_LIST,
  GET_DOCUMENTS_BERKAS_CLIENT_LIST_SUCCESS,
  GET_DOCUMENTS_BERKAS_CLIENT_LIST_ERROR,
} from "./action";

const INIT_STATE = {
  listCompany: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  listClient: {
    data: null,
    idCompany: null,
    year: null,
    search: null,
    loading: false,
    error: false,
    message: null,
  },
  listCheckBerkas: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  listBerkasClient: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  listDocuments: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MASTER_COMPANY_LIST:
      return {
        ...state,
        listCompany: {
          ...state.listCompany,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_MASTER_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        listCompany: {
          ...state.listCompany,
          data: action.payload.data.map((t) => {
            return {
              ...t,
            };
          }),
          loading: false,
          error: false,
          message: "Success",
        },
      };

    case GET_MASTER_COMPANY_LIST_ERROR:
      return {
        ...state,
        listCompany: {
          ...state.listCompany,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case GET_CLIENT_LIST:
      return {
        ...state,
        listClient: {
          ...state.listClient,
          data: null,
          idCompany: action.payload.id,
          year: action.payload.year,
          search: action.payload.search,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        listClient: {
          ...state.listClient,
          data: action.payload.data.map((t) => {
            return {
              ...t,
            };
          }),
          loading: false,
          error: false,
          message: "Success",
        },
      };

    case GET_CLIENT_LIST_ERROR:
      return {
        ...state,
        listClient: {
          ...state.listClient,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case CHECK_BERKAS:
      return {
        ...state,
        listCheckBerkas: {
          ...state.listCheckBerkas,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case CHECK_BERKAS_SUCCESS:
      return {
        ...state,
        listCheckBerkas: {
          ...state.listCheckBerkas,
          data: action?.payload?.data,
          loading: false,
          error: false,
          message: "Success",
        },
      };

    case CHECK_BERKAS_ERROR:
      return {
        ...state,
        listCheckBerkas: {
          ...state.listCheckBerkas,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case GET_BERKAS_CLIENT_LIST:
      return {
        ...state,
        listBerkasClient: {
          ...state.listBerkasClient,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_BERKAS_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        listBerkasClient: {
          ...state.listBerkasClient,
          data: action?.payload?.data,
          loading: false,
          error: false,
          message: "Success",
        },
      };

    case GET_BERKAS_CLIENT_LIST_ERROR:
      return {
        ...state,
        listBerkasClient: {
          ...state.listBerkasClient,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };

    case GET_DOCUMENTS_BERKAS_CLIENT_LIST:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };

    case GET_DOCUMENTS_BERKAS_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          data: action?.payload?.data,
          loading: false,
          error: false,
          message: "Success",
        },
      };

    case GET_DOCUMENTS_BERKAS_CLIENT_LIST_ERROR:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          data: null,
          loading: false,
          error: true,
          page: INIT_PAGE_STATE,
          message: action.payload,
        },
      };
    default:
      return state;
  }
};
