import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_MASTER_FORM_FIELD_LIST = "LOAD_MASTER_FORM_FIELD_LIST";
export const LOAD_MASTER_FORM_FIELD_LIST_SUCCESS =
  "LOAD_MASTER_FORM_FIELD_LIST_SUCCESS";
export const LOAD_MASTER_FORM_FIELD_LIST_ERROR =
  "LOAD_MASTER_FORM_FIELD_LIST_ERROR";

export const ADDEDIT_MASTER_FORM_FIELD = "ADDEDIT_MASTER_FORM_FIELD";
export const SAVE_ADDEDIT_MASTER_FORM_FIELD = "SAVE_ADDEDIT_MASTER_FORM_FIELD";
export const SAVE_ADDEDIT_MASTER_FORM_FIELD_SUCCESS =
  "SAVE_ADDEDIT_MASTER_FORM_FIELD_SUCCESS";
export const SAVE_ADDEDIT_MASTER_FORM_FIELD_ERROR =
  "SAVE_ADDEDIT_MASTER_FORM_FIELD_ERROR";
export const CLOSE_ADDEDIT_MASTER_FORM_FIELD =
  "CLOSE_ADDEDIT_MASTER_FORM_FIELD";

export const DELETE_MASTER_FORM_FIELD = "DELETE_MASTER_FORM_FIELD";
export const DELETE_MASTER_FORM_FIELD_SUCCESS =
  "DELETE_MASTER_FORM_FIELD_SUCCESS";
export const DELETE_MASTER_FORM_FIELD_ERROR = "DELETE_MASTER_FORM_FIELD_ERROR";

export const loadMasterFormFieldList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_FORM_FIELD_LIST,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
      idDocumentType: param.idDocumentType,
    },
  };
};

export const loadMasterFormFieldListSuccess = (data) => ({
  type: LOAD_MASTER_FORM_FIELD_LIST_SUCCESS,
  payload: data,
});

export const loadMasterFormFieldListError = (error) => ({
  type: LOAD_MASTER_FORM_FIELD_LIST_ERROR,
  payload: error,
});

export const addeditMasterFormField = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_FORM_FIELD,
  payload: {
    item,
    mode,
  },
});

export const closeAddeditMasterFormField = () => ({
  type: CLOSE_ADDEDIT_MASTER_FORM_FIELD,
});

export const saveAddeditMasterFormField = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_MASTER_FORM_FIELD,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditMasterFormFieldSuccess = (data) => ({
  type: SAVE_ADDEDIT_MASTER_FORM_FIELD_SUCCESS,
  payload: data,
});

export const saveAddeditMasterFormFieldError = (error) => ({
  type: SAVE_ADDEDIT_MASTER_FORM_FIELD_ERROR,
  payload: error,
});

export const deleteMasterFormField = (data, confirmed = false) => ({
  type: DELETE_MASTER_FORM_FIELD,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteMasterFormFieldSuccess = () => ({
  type: DELETE_MASTER_FORM_FIELD_SUCCESS,
});

export const deleteMasterFormFieldError = (error) => ({
  type: DELETE_MASTER_FORM_FIELD_ERROR,
  payload: error,
});
