/* eslint-disable import/no-cycle */
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";
// import { getFormData } from '../../helpers/Utils';
import * as action from "./action";

export const getListState = (state) => state.countryApp.list;

const loadMasterCountryAsync = async (query) => GET(`/country?${query}`);

const addMasterCountryAsync = async (item) => POST(`/country`, item);
const editMasterCountryAsync = async (id, item) => PUT(`/country/${id}`, item);
const deleteMasterCountryAsync = async (id) => DELETE(`/country/${id}`);

function* loadMasterCountryList({ payload }) {
  try {
    const list = yield select(getListState);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadMasterCountryAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterCountryListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterCountryListError(error));
  }
}

function* saveAddeditMasterCountry({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editMasterCountryAsync,
          payload.item.id,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addMasterCountryAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditMasterCountrySuccess(response));
      yield put(action.loadMasterCountryList());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditMasterCountryError(error));
  }
}

function* deleteMasterCountry({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteMasterCountryAsync, payload.item.id);
        yield put(action.deleteMasterCountrySuccess());
        yield put(action.loadMasterCountryList());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteMasterCountryError(error));
  }
}

export function* watchLoadMasterCountryList() {
  yield takeEvery(action.LOAD_MASTER_COUNTRY_LIST, loadMasterCountryList);
}

export function* watchSaveAddeditMasterCountry() {
  yield takeEvery(action.SAVE_ADDEDIT_MASTER_COUNTRY, saveAddeditMasterCountry);
}

export function* watchDeleteMasterCountry() {
  yield takeEvery(action.DELETE_MASTER_COUNTRY, deleteMasterCountry);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterCountryList),
    fork(watchSaveAddeditMasterCountry),
    fork(watchDeleteMasterCountry),
  ]);
}
