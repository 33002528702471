/* eslint-disable import/no-cycle */
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";
// import { getFormData } from '../../helpers/Utils';
import * as action from "./action";

export const getListState = (state) => state.dashboardApp.statTask;
export const getListNeedAttState = (state) => state.dashboardApp.needAtt;

const loadStatTaskListAsync = async () => GET(`/dashboard/getStatTask`);
const loadClientNeedAttAsync = async () => GET(`/client/needAtt`);
const loadStatNeedAttAsync = async () => GET(`/dashboard/needAtt`);

function* loadStatTaskList() {
  try {
    const response = yield call(
      loadStatTaskListAsync
    );
    yield put(action.loadStatTaskListSuccess(response));
  } catch (error) {
    yield put(action.loadStatTaskListError(error));
  }
}

function* loadStatNeedAtt() {
  try {
    const response = yield call(
      loadStatNeedAttAsync
    );
    yield put(action.loadStatNeedAttSuccess(response));
  } catch (error) {
    yield put(action.loadStatNeedAttError(error));
  }
}

function* loadClientNeedAtt() {
  try {
    const response = yield call(
      loadClientNeedAttAsync
    );
    yield put(action.loadClientNeedAttSuccess(response));
  } catch (error) {
    yield put(action.loadClientNeedAttError(error));
  }
}

export function* watchLoadStatTaskList() {
  yield takeEvery(action.LOAD_STAT_TASK_LIST, loadStatTaskList);
}

export function* watchLoadStatNeedAtt() {
  yield takeEvery(action.LOAD_STAT_NEED_ATT, loadStatNeedAtt);
}

export function* watchLoadClientNeedAtt() {
  yield takeEvery(action.LOAD_CLIENT_NEED_ATT, loadClientNeedAtt);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadStatTaskList),
    fork(watchLoadClientNeedAtt),
    fork(watchLoadStatNeedAtt),
  ]);
}
