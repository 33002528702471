import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../helpers/toastHelper";
import * as action from "./action";

export const getListState = (state) => state.clientApp.list;

const loadMasterClientListAsync = async (query) => GET(`/client?${query}`);
const loadMasterCompanyListAsync = async () => GET(`/company`);
const addMasterClientAsync = async (item) => POST(`/client`, item);
const editMasterClientAsync = async (id, item) => PUT(`/client/${id}`, item);
const deleteMasterClientAsync = async (id) => DELETE(`/client/${id}`);
const loadDocumentTypeListAsync = async () => GET(`/documentType/client`);
const getListDocTypeClientAsync = async (id) =>
  GET(`/client-access-doc/client/${id}`);
const addFormValueFieldAsync = async (item) => POST(`/form-value`, item);
// const getFormValueListAsync = async (client_id, document_id) =>
//   GET(`/form-value?client_id=${client_id}&document_id=${document_id}`);

const getFormValueListAsync = async (client_id) =>
  GET(`/form-value?client_id=${client_id}`);

function* loadMasterClientList({ payload }) {
  try {
    const list = yield select(getListState);

    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadMasterClientListAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterClientListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterClientListError(error));
  }
}

function* loadMasterCompanyList() {
  try {
    const response = yield call(loadMasterCompanyListAsync);
    yield put(action.getCompanyListSuccess(response));
  } catch (error) {
    yield put(action.getCompanyListError(error));
  }
}

function* loadDocumentTypeList() {
  try {
    const response = yield call(loadDocumentTypeListAsync);
    yield put(action.getAllDocumentListSuccess(response));
  } catch (error) {
    yield put(action.getAllDocumentListError(error));
  }
}

function* getListDocTypeClientFunction({ payload }) {
  let response = null;
  try {
    response = yield call(getListDocTypeClientAsync, payload.id);
    yield put(action.getListFormFieldClientSuccess(response));
  } catch (error) {
    yield put(action.getListFormFieldClientError(error));
  }
}

function* getListFomValueFunction({ payload }) {
  console.log("payloadddd", payload);
  let response = null;
  try {
    // response = yield call(
    //   getFormValueListAsync,
    //   payload.client_id,
    //   payload.document_id
    // );
    response = yield call(getFormValueListAsync, payload.client_id);
    yield put(action.getFormValueListSuccess(response));
  } catch (error) {
    yield put(action.getFormValueListError(error));
  }
}

function* saveAddeditMasterClient({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editMasterClientAsync,
          payload.item.id,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addMasterClientAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditMasterClientSuccess(response));
      yield put(action.loadMasterClientList());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditMasterClientError(error));
  }
}

function* deleteMasterClient({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteMasterClientAsync, payload.item.id);
        yield put(action.deleteMasterClientSuccess());
        yield put(action.loadMasterClientList());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteMasterClientError(error));
  }
}

// ADD FORM VALUE
function* saveAddeditFormValueField({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      response = yield call(addFormValueFieldAsync, payload.item);
      yield put(action.saveAddeditFormValueSuccess(response));
      yield put(action.getListFormFieldClient(payload?.item?.client_id));
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditFormValueError(error));
  }
}

export function* watchLoadMasterClientList() {
  yield takeEvery(action.LOAD_MASTER_CLIENT_LIST, loadMasterClientList);
}

export function* watchLoadMasterCompanyList() {
  yield takeEvery(action.ADDEDIT_MASTER_CLIENT, loadMasterCompanyList);
}

export function* watchSaveAddeditMasterClient() {
  yield takeEvery(action.SAVE_ADDEDIT_MASTER_CLIENT, saveAddeditMasterClient);
}

export function* watchDeleteMasterClient() {
  yield takeEvery(action.DELETE_MASTER_CLIENT, deleteMasterClient);
}

export function* watchLoadDocumentTypeList() {
  yield takeEvery(action.GET_ALL_DOCUMENT, loadDocumentTypeList);
}

export function* watchGetListDocTypeClient() {
  yield takeEvery(
    action.GET_LIST_FORM_FIELD_CLIENT,
    getListDocTypeClientFunction
  );
}

export function* watchSaveAddEditFormValue() {
  yield takeEvery(action.SAVE_ADDEDIT_FORM_VALUE, saveAddeditFormValueField);
}

export function* watchGetFormValueList() {
  yield takeEvery(action.GET_FORM_VALUE, getListFomValueFunction);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterClientList),
    fork(watchLoadMasterCompanyList),
    fork(watchSaveAddeditMasterClient),
    fork(watchDeleteMasterClient),
    fork(watchLoadDocumentTypeList),
    fork(watchGetListDocTypeClient),
    fork(watchSaveAddEditFormValue),
    fork(watchGetFormValueList),
  ]);
}
