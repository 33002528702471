import { INIT_PAGE_STATE } from "../../constants/constants";

export const LOAD_MASTER_CLIENT_LIST = "LOAD_MASTER_CLIENT_LIST";
export const LOAD_MASTER_CLIENT_LIST_SUCCESS =
  "LOAD_MASTER_CLIENT_LIST_SUCCESS";
export const LOAD_MASTER_CLIENT_LIST_ERROR = "LOAD_MASTER_CLIENT_LIST_ERROR";

export const ADDEDIT_MASTER_CLIENT = "ADDEDIT_MASTER_CLIENT";
export const ADDEDIT_MASTER_CLIENT_SUCCESS = "ADDEDIT_MASTER_CLIENT_SUCCESS";
export const ADDEDIT_MASTER_CLIENT_ERROR = "ADDEDIT_MASTER_CLIENT_ERROR";
export const SAVE_ADDEDIT_MASTER_CLIENT = "SAVE_ADDEDIT_MASTER_CLIENT";
export const SAVE_ADDEDIT_MASTER_CLIENT_SUCCESS =
  "SAVE_ADDEDIT_MASTER_CLIENT_SUCCESS";
export const SAVE_ADDEDIT_MASTER_CLIENT_ERROR =
  "SAVE_ADDEDIT_MASTER_CLIENT_ERROR";
export const CLOSE_ADDEDIT_MASTER_CLIENT = "CLOSE_ADDEDIT_MASTER_CLIENT";

export const DELETE_MASTER_CLIENT = "DELETE_MASTER_CLIENT";
export const DELETE_MASTER_CLIENT_SUCCESS = "DELETE_MASTER_CLIENT_SUCCESS";
export const DELETE_MASTER_CLIENT_ERROR = "DELETE_MASTER_CLIENT_ERROR";

// BIND DATA LIST COMPANY
export const GET_MASTER_COMPANY_LIST = "GET_MASTER_COMPANY_LIST";
export const GET_MASTER_COMPANY_LIST_SUCCESS =
  "GET_MASTER_COMPANY_LIST_SUCCESS";
export const GET_MASTER_COMPANY_LIST_ERROR = "GET_MASTER_COMPANY_LIST_ERROR";

// BIND DATA LIST COMPANY
export const GET_ALL_DOCUMENT = "GET_ALL_DOCUMENT";
export const GET_ALL_DOCUMENT_SUCCESS = "GET_ALL_DOCUMENT_SUCCESS";
export const GET_ALL_DOCUMENT_ERROR = "GET_ALL_DOCUMENT_ERROR";

export const GET_LIST_FORM_FIELD_CLIENT = "GET_LIST_FORM_FIELD_CLIENT";
export const GET_LIST_FORM_FIELD_CLIENT_SUCCESS =
  "GET_LIST_FORM_FIELD_CLIENT_SUCCESS";
export const GET_LIST_FORM_FIELD_CLIENT_ERROR =
  "GET_LIST_FORM_FIELD_CLIENT_ERROR";

// INSERT FORM VALUE
export const SAVE_ADDEDIT_FORM_VALUE = "SAVE_ADDEDIT_FORM_VALUE";
export const SAVE_ADDEDIT_FORM_VALUE_SUCCESS =
  "SAVE_ADDEDIT_FORM_VALUE_SUCCESS";
export const SAVE_ADDEDIT_FORM_VALUE_ERROR = "SAVE_ADDEDIT_FORM_VALUE_ERROR";

// GET FORM VALUE
export const GET_FORM_VALUE = "GET_FORM_VALUE";
export const GET_FORM_VALUE_SUCCESS = "GET_FORM_VALUE_SUCCESS";
export const GET_FORM_VALUE_ERROR = "GET_FORM_VALUE_ERROR";

export const loadMasterClientList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_CLIENT_LIST,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterClientListSuccess = (data) => ({
  type: LOAD_MASTER_CLIENT_LIST_SUCCESS,
  payload: data,
});

export const loadMasterClientListError = (error) => ({
  type: LOAD_MASTER_CLIENT_LIST_ERROR,
  payload: error,
});

export const addeditMasterClient = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_CLIENT,
  payload: {
    item,
    mode,
  },
});

export const addEditMasterClientSuccess = (data) => ({
  type: ADDEDIT_MASTER_CLIENT_SUCCESS,
  payload: data,
});

export const addEditMasterClientError = (error) => ({
  type: ADDEDIT_MASTER_CLIENT_ERROR,
  payload: error,
});

export const closeAddeditMasterClient = () => ({
  type: CLOSE_ADDEDIT_MASTER_CLIENT,
});

export const saveAddeditMasterClient = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_MASTER_CLIENT,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditMasterClientSuccess = (data) => ({
  type: SAVE_ADDEDIT_MASTER_CLIENT_SUCCESS,
  payload: data,
});

export const saveAddeditMasterClientError = (error) => ({
  type: SAVE_ADDEDIT_MASTER_CLIENT_ERROR,
  payload: error,
});

export const deleteMasterClient = (data, confirmed = false) => ({
  type: DELETE_MASTER_CLIENT,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteMasterClientSuccess = () => ({
  type: DELETE_MASTER_CLIENT_SUCCESS,
});

export const deleteMasterClientError = (error) => ({
  type: DELETE_MASTER_CLIENT_ERROR,
  payload: error,
});

export const getCompanyList = () => ({
  type: GET_MASTER_COMPANY_LIST,
});

export const getCompanyListSuccess = (data) => ({
  type: GET_MASTER_COMPANY_LIST_SUCCESS,
  payload: data,
});

export const getCompanyListError = (error) => ({
  type: GET_MASTER_COMPANY_LIST_ERROR,
  payload: error,
});

export const getAllDocumentList = () => ({
  type: GET_ALL_DOCUMENT,
});

export const getAllDocumentListSuccess = (data) => ({
  type: GET_ALL_DOCUMENT_SUCCESS,
  payload: data,
});

export const getAllDocumentListError = (error) => ({
  type: GET_ALL_DOCUMENT_ERROR,
  payload: error,
});

export const getListFormFieldClient = (id) => ({
  type: GET_LIST_FORM_FIELD_CLIENT,
  payload: { id },
});

export const getListFormFieldClientSuccess = (data) => ({
  type: GET_LIST_FORM_FIELD_CLIENT_SUCCESS,
  payload: data,
});

export const getListFormFieldClientError = (error) => ({
  type: GET_LIST_FORM_FIELD_CLIENT_ERROR,
  payload: error,
});

export const saveAddeditFormValue = (item) => ({
  type: SAVE_ADDEDIT_FORM_VALUE,
  payload: {
    item,
  },
});

export const saveAddeditFormValueSuccess = (data) => ({
  type: SAVE_ADDEDIT_FORM_VALUE_SUCCESS,
  payload: data,
});

export const saveAddeditFormValueError = (error) => ({
  type: SAVE_ADDEDIT_FORM_VALUE_ERROR,
  payload: error,
});

// export const getFormValueList = (client_id, document_id) => ({
//   type: GET_FORM_VALUE,
//   payload: { client_id, document_id },
// });

export const getFormValueList = (client_id) => ({
  type: GET_FORM_VALUE,
  payload: { client_id },
});

export const getFormValueListSuccess = (data) => ({
  type: GET_FORM_VALUE_SUCCESS,
  payload: data,
});

export const getFormValueListError = (error) => ({
  type: GET_FORM_VALUE_ERROR,
  payload: error,
});
