import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_MASTER_COUNTRY_LIST = "LOAD_MASTER_COUNTRY_LIST";
export const LOAD_MASTER_COUNTRY_LIST_SUCCESS =
  "LOAD_MASTER_COUNTRY_LIST_SUCCESS";
export const LOAD_MASTER_COUNTRY_LIST_ERROR = "LOAD_MASTER_COUNTRY_LIST_ERROR";

export const ADDEDIT_MASTER_COUNTRY = "ADDEDIT_MASTER_COUNTRY";
export const SAVE_ADDEDIT_MASTER_COUNTRY = "SAVE_ADDEDIT_MASTER_COUNTRY";
export const SAVE_ADDEDIT_MASTER_COUNTRY_SUCCESS =
  "SAVE_ADDEDIT_MASTER_COUNTRY_SUCCESS";
export const SAVE_ADDEDIT_MASTER_COUNTRY_ERROR =
  "SAVE_ADDEDIT_MASTER_COUNTRY_ERROR";
export const CLOSE_ADDEDIT_MASTER_COUNTRY = "CLOSE_ADDEDIT_MASTER_COUNTRY";

export const DELETE_MASTER_COUNTRY = "DELETE_MASTER_COUNTRY";
export const DELETE_MASTER_COUNTRY_SUCCESS = "DELETE_MASTER_COUNTRY_SUCCESS";
export const DELETE_MASTER_COUNTRY_ERROR = "DELETE_MASTER_COUNTRY_ERROR";

export const loadMasterCountryList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_COUNTRY_LIST,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterCountryListSuccess = (data) => ({
  type: LOAD_MASTER_COUNTRY_LIST_SUCCESS,
  payload: data,
});

export const loadMasterCountryListError = (error) => ({
  type: LOAD_MASTER_COUNTRY_LIST_ERROR,
  payload: error,
});

export const addeditMasterCountry = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_COUNTRY,
  payload: {
    item,
    mode,
  },
});

export const closeAddeditMasterCountry = () => ({
  type: CLOSE_ADDEDIT_MASTER_COUNTRY,
});

export const saveAddeditMasterCountry = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_MASTER_COUNTRY,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditMasterCountrySuccess = (data) => ({
  type: SAVE_ADDEDIT_MASTER_COUNTRY_SUCCESS,
  payload: data,
});

export const saveAddeditMasterCountryError = (error) => ({
  type: SAVE_ADDEDIT_MASTER_COUNTRY_ERROR,
  payload: error,
});

export const deleteMasterCountry = (data, confirmed = false) => ({
  type: DELETE_MASTER_COUNTRY,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteMasterCountrySuccess = () => ({
  type: DELETE_MASTER_COUNTRY_SUCCESS,
});

export const deleteMasterCountryError = (error) => ({
  type: DELETE_MASTER_COUNTRY_ERROR,
  payload: error,
});
